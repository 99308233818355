import { useMemo, useState } from "react";
import { AuthContext } from "../contexts/AuthContext/AuthContext";

const parseJwt = (token: any) => {
  try {
    return JSON.parse(window.atob(token.split(".")[1]));
  } catch (error) {
    return null;
  }
};

export default function AuthProvider({ children }: any) {
  const [user, setUser] = useState("");

  // call this function when you want to authenticate the user
  const login = async (data: any) => {
    setUser(data);
  };

  // call this function to sign out logged in user
  const logout = () => {
    localStorage.clear();
    setUser("");
  };

  const tokenVerify = () => {
    const token = localStorage.getItem("token");

    if (!token) return false;

    const decodedJWT = parseJwt(token);

    if (decodedJWT.exp * 1000 < Date.now()) return false;

    return true;
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      tokenVerify,
    }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
